import request from '@/common/utils/request'
import Vue from "vue";



export function add(data) {
  return request({
    url: Vue.prototype.$cmsBaseUrl + '/adminapi/activity/saveActivity',
    method: 'post',
    data
  })
}
export function doEdit(data) {
  return request({
    url: Vue.prototype.$cmsBaseUrl + '/adminapi/cmssubject/wx/update',
    method: 'post',
    data
  })
}
export function getList(params) {
    return request({
      url: Vue.prototype.$cmsBaseUrl + '/adminapi/cmssubject/wx/list',
      method: 'get',
      params
    })
  }export function doDELETE(data) {
    return request({
      url: Vue.prototype.$cmsBaseUrl + '/adminapi/activity/deleteActivity',
      method: 'post',
      data
    })
  }